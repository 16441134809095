$maxMobile: 712px;
$maxPopUp: 1160px;
$maxTablet: 936px;
$maxLaptop: 1224px;

@mixin forPhone {
  @media (max-width: $maxMobile) {
    @content;
  }
}

@mixin forPopUp {
  @media (max-width: $maxPopUp) {
    @content;
  }
}

@mixin forTablet {
  @media (max-width: $maxTablet) {
    @content;
  }
}

@mixin forLaptop {
  @media (max-width: $maxLaptop) {
    @content;
  }
}

@mixin forDesktopOnly {
  @media (min-width: $maxTablet) {
    @content;
  }
}

$desktopContentMaxWidth: 930px;
$tabletContentMaxWidth: 692px;
$phoneContentWidth: 100%;

$mobileHorizontalPadding: 24px;
$tabletHorizontalPadding: 36px;
$desktopHorizontalPadding: 48px;

@mixin setMaxContentWidth() {
  width: 100%;
  max-width: $desktopContentMaxWidth + $desktopHorizontalPadding * 2;

  @include forTablet {
    max-width: $tabletContentMaxWidth + $tabletHorizontalPadding * 2;
  }

  @include forPhone {
    max-width: $phoneContentWidth;
  }
}

@mixin setHorizontalPaddings {
  padding-left: $desktopHorizontalPadding;
  padding-right: $desktopHorizontalPadding;

  @include forTablet {
    padding-left: $tabletHorizontalPadding;
    padding-right: $tabletHorizontalPadding;
  }

  @include forPhone {
    padding-left: $mobileHorizontalPadding;
    padding-right: $mobileHorizontalPadding;
  }
}
