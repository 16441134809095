@import 'src/product_modules/styles/variables';
@import 'src/styles/media';

.closeBtn {
  border: none;
  background-color: transparent;
  height: 36px;
  width: 36px;
  padding: 0;
  margin-left: 24px;
  cursor: pointer;
  flex-shrink: 0;

  @include forPhone {
    margin-left: 12px;
  }

  &:focus {
    outline: none;
  }

  & svg {
    width: 100%;
    height: auto;

    & path {
      stroke: $defaultTextColor;
    }
  }

  &__disabled {
    cursor: default;

    svg path {
      stroke: $primary20;
    }
  }
}

.header {
  position: relative;
}
