@import 'src/product_modules/styles/variables';

.buttonWithImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  background: none;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $secondary100Base;
  cursor: pointer;
  padding: 0 8px;

  &:focus {
    color: $secondary100Base;

    > svg {
      stroke: $secondary100Base;
    }
  }

  &:hover,
  &:focus-visible {
    color: $defaultLinkHoverColor;

    > svg {
      stroke: $defaultLinkHoverColor;
    }
  }

  &:active {
    outline: none;
  }

  & span {
    color: #ffffff;
    opacity: 0.5;
  }
}

.reversedButton {
  flex-direction: row-reverse;
}

.imageStyles {
  height: 24px;
  width: 24px;
  stroke: $secondary100Base;
}

.downloadImage {
  @extend .imageStyles;
  margin-right: 8px;
}

.uploadImage {
  @extend .downloadImage;
}

.addImage {
  @extend .imageStyles;
  margin-right: 5px;
}

.shareImage {
  width: 20px;
  height: 20px;
  stroke: $secondary100Base;
}

.editImage {
  @extend .imageStyles;

  margin-right: 4px;
}

.expandImage,
.collapseImage {
  @extend .imageStyles;

  transform: rotate(45deg);
}

.disabled {
  color: $primary40;

  svg path {
    stroke: $primary40;
  }

  &:hover {
    color: $primary40;
    cursor: default;
  }
}

.leftArrowImage, .rightArrowImage {
  path {
    stroke: $secondary100Base;
  }
}

.chevronIcon {
  path {
    stroke: $secondary100Base;
  }
}
