@import 'src/product_modules/styles/variables';

.tooltip {
  > div {
    min-width: 206px;
    padding: 12px 20px;
    background-color: $white100Base;
    border-radius: 4px;
    box-shadow: 0 8px 24px rgba(15, 35, 66, 0.2);
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
