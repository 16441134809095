@import 'styles/variables';
@import '~reset-css/sass/reset';

html {
  height: 100%;
  font-family: $fontFamilyMonsterratRegular;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, .25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .1);
  border-radius: 0;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'), url('./static/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Medium'), url('./static/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-SemiBold'), url('./static/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Bold'), url('./static/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}


:root {
  --base-borderRadiusDefault: 4px;
  --base-primaryBackground: #e5e5e5;

  --base-lightBlue: #d0e4f1;

  --base-middleGray: #c3c8cd;

  --base-fontFamilyMonsterrat: 'Montserrat', 'sans-serif';
  --base-fontFamilySourceCodePro: 'Source Code Pro';
  --base-fontFamilyMonaco: 'Monaco', 'monospace', 'courier';
  --base-fontFamilyVerdana: 'Verdana';

  --base-h1FontSize: 48px;
  --base-h2FontSize: 36px;
  --base-h3FontSize: 28px;
  --base-h4FontSize: 22px;
  --base-h5FontSize: 18px;
  --base-h6FontSize: 14px;
  --base-h1LineHeight: 56px;
  --base-h2LineHeight: 44px;
  --base-h3LineHeight: 36px;
  --base-h4LineHeight: 28px;
  --base-h5LineHeight: 24px;
  --base-h6LineHeight: 20px;

  --base-pFontSize: 14px;
  --base-pLineHeight: 20px;
  --base-smallFontSize: 12px;
  --base-smallLineHeight: 16px;
  --base-tableHeadFontSize: 9px;
  --base-tableHeadLineHeight: 12px;
  --base-tagFontSize: 9px;
  --base-tagLineHeight: 10px;
  --base-menuHeaderFontSize: 10px;
  --base-menuHeaderLineHeight: 12px;

  --base-defaultLetterSpacing: 0.1em;

  --base-seminormalFontWeight: 400;
  --base-normalFontWeight: 500;
  --base-semiboldFontWeight: 600;
  --base-boldFontWeight: 700;

  --base-bottomPaddingDefault: 60px;

  --base-tableBodyCellVerticalPadding: 14px;
  --base-tableBodyCellHorizontalPadding: 10px;

  --base-tableHeadStickyZIndex: 2;

  --base-transitionDuration: 0.25s;
  
  --base-primary120: #060d19;
  --base-primary100Base: #0f2342;
  --base-primary80: #3f4f68;
  --base-primary60: #6f7b8e;
  --base-primary40: #9fa7b3;
  --base-primary20: #cfd3d9;
  --base-primary10: #e7e9ec;
  --base-primary10-10: #e7e9ec10;
  --base-primary6: #f1f2f4;
  --base-primary2: #fafbfb;

  --base-secondary120: #077ddf;
  --base-secondary100Base: #1e95f8;
  --base-secondary40: #a5d5fc;
  --base-secondary10: #e8f4fe;
  --base-secondary6: #f2f9ff;
  --base-secondary3: #f8fcff;

  --base-red100Base: #f76565;
  --base-red50: #fbb2b2;
  --base-red10: #fef0f0;

  --base-orange120: #ff4909;
  --base-orange100Base: #ff6b37;
  --base-orange50: #ffb59b;
  --base-orange10: #fff0eb;

  --base-green110: #00c4ac;
  --base-green100Base: #00c3ac;
  --base-green50: #80e1d6;
  --base-green10: #e5f9f7;

  --base-lightOrange100Base: #ff8a00;
  --base-lightOrange50: #ffc480;
  --base-lightOrange10: #fff3e5;

  --base-darkBlue120: #042d57;
  --base-darkBlue110: #053361;
  --base-darkBlue100Base: #053668;
  --base-darkBlue90: #1e4a77;
  --base-darkBlue20: #cdd7e1;

  --base-white100Base: #ffffff;

  --base-violet20: #f4eafe;
  --base-violet100Base: #8924ef;

  --base-grey: #777c91;

  --base-surfacesPrimary: var(--base-white100Base);
  --base-surfacesSidebar: var(--base-darkBlue100Base);
  --base-surfacesOverlaySolid: #9295a2;
  --base-surfacesOverlay: rgba(var(--base-surfacesOverlaySolid), 0.4);
  --base-surfacesLight: #f1f2f4;
}
