@import 'src/styles/variables';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spinner {
  margin-left: calc(50% - 26px);
}

.spinnerRoot {
  & > div:first-child {
    color: $primary10 !important; // We have to use !important because product component adds styles directly to style attribute
  }
}
