@import 'src/product_modules/styles/variables';

.popup {
  &Header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 36px 36px 0 55px;
  }

  &Title {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

.titleText {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0;
  width: 100%;
}

.questionIcon {
  margin-left: 12px;
}

.titleIconsContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
