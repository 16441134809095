$primary120: var(--base-primary120);
$primary100Base: var(--base-primary100Base);
$primary80: var(--base-primary80);
$primary60: var(--base-primary60);
$primary40: var(--base-primary40);
$primary20: var(--base-primary20);
$primary10: var(--base-primary10);
$primary6: var(--base-primary6);
$primary2: var(--base-primary2);

$secondary120: var(--base-secondary120);
$secondary100Base: var(--base-secondary100Base);
$secondary40: var(--base-secondary40);
$secondary10: var(--base-secondary10);
$secondary6: var(--base-secondary6);
$secondary3: var(--base-secondary3);

$red100Base: var(--base-red100Base);
$red50: var(--base-red50);
$red10: var(--base-red10);

$orange120: var(--base-orange120);
$orange100Base: var(--base-orange100Base);
$orange50: var(--base-orange50);
$orange10: var(--base-orange10);

$green110: var(--base-green110);
$green100Base: var(--base-green100Base);
$green50: var(--base-green50);
$green10: var(--base-green10);

$lightOrange100Base: var(--base-lightOrange100Base);
$lightOrange50: var(--base-lightOrange50);
$lightOrange10: var(--base-lightOrange10);

$darkBlue120: var(--base-darkBlue120);
$darkBlue110: var(--base-darkBlue110);
$darkBlue100Base: var(--base-darkBlue100Base);
$darkBlue90: var(--base-darkBlue90);
$darkBlue20: var(--base-darkBlue20);

$white100Base: var(--base-white100Base);

$violet20: var(--base-violet20);
$violet100Base: var(--base-violet100Base);

$grey: var(--base-grey);

$surfacesPrimary: var(--base-surfacesPrimary);
$surfacesSidebar: var(--base-surfacesSidebar);
$surfacesOverlaySolid: var(--base-surfacesOverlaySolid);
$surfacesOverlay: var(--base-surfacesOverlay);
$surfacesLight: var(--base-surfacesLight);
