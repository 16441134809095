@import 'src/product_modules/styles/colorPalette';

$fontFamilyMonsterrat: var(--base-fontFamilyMonsterrat);
$fontFamilySourceCodePro: var(--base-fontFamilySourceCodePro);
$fontFamilyMonaco: var(--base-fontFamilyMonaco);
$fontFamilyVerdana: var(--base-fontFamilyVerdana);

$h1FontSize: var(--base-h1FontSize);
$h2FontSize: var(--base-h2FontSize);
$h3FontSize: var(--base-h3FontSize);
$h4FontSize: var(--base-h4FontSize);
$h5FontSize: var(--base-h5FontSize);
$h6FontSize: var(--base-h6FontSize);
$h1LineHeight: var(--base-h1LineHeight);
$h2LineHeight: var(--base-h2LineHeight);
$h3LineHeight: var(--base-h3LineHeight);
$h4LineHeight: var(--base-h4LineHeight);
$h5LineHeight: var(--base-h5LineHeight);
$h6LineHeight: var(--base-h6LineHeight);

$pFontSize: var(--base-pFontSize);
$pLineHeight: var(--base-pLineHeight);
$smallFontSize: var(--base-smallFontSize);
$smallLineHeight: var(--base-smallLineHeight);
$tableHeadFontSize: var(--base-tableHeadFontSize);
$tableHeadLineHeight: var(--base-tableHeadLineHeight);
$tagFontSize: var(--base-tagFontSize);
$tagLineHeight: var(--base-tagLineHeight);
$menuHeaderFontSize: var(--base-menuHeaderFontSize);
$menuHeaderLineHeight: var(--base-menuHeaderLineHeight);

$defaultLetterSpacing: var(--base-defaultLetterSpacing);

$seminormalFontWeight: var(--base-seminormalFontWeight);
$normalFontWeight: var(--base-normalFontWeight);
$semiboldFontWeight: var(--base-semiboldFontWeight);
$boldFontWeight: var(--base-boldFontWeight);

$headerTextColor: $primary100Base;
$defaultTextColor: $primary80;
$subtextColor: $primary40;
$captionColor: $primary20;
$lightCaptionColor: $primary10;
$placeholderTextColor: $primary40;

$contrastSurfacesTextColor: $white100Base;
$contrastSurfacesIconColor: $white100Base;
$contrastSurfacesAciveTextColor: $orange100Base;
$contrastSurfacesActiveIconColor: $orange100Base;

$defaultErrorColor: $red100Base;
$defaultLinkColor: $secondary100Base;
$defaultLinkHoverColor: $secondary120;

$borderAndSeparatorsColor: $primary10;

$inputHoverBorderColor: $primary40;

$borderRadiusDefault: var(--base-borderRadiusDefault);
$bottomPaddingDefault: var(--base-bottomPaddingDefault);

$tableBodyCellVerticalPadding: var(--base-tableBodyCellVerticalPadding);
$tableBodyCellHorizontalPadding: var(--base-tableBodyCellHorizontalPadding);

$tableHeadStickyZIndex: var(--base-tableHeadStickyZIndex);

$transitionDuration: var(--base-transitionDuration);

$violet20: var(--base-violet20);
$violet100Base: var(--base-violet100Base);
