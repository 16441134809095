@import 'colors';


$seminormalFontWeight: 400;
$normalFontWeight: 500;
$semiboldFontWeight: 600;
$boldFontWeight: 700;

$fontFamilyMonsterratRegular: 'Montserrat', 'sans-serif';
$fontFamilyMonsterratBold: 'Montserrat', 'sans-serif';

$borderRadiusDefault: var(--base-borderRadiusDefault);

$defaultErrorColor: $red100Base;
