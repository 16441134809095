@import 'src/product_modules/styles/variables';
@import 'src/product_modules/components/Tooltip/Common.module';

.tooltip {
  > div {
    background-color: $white100Base;
    border: 1px solid $primary120;
    box-sizing: border-box;
    border-radius: $borderRadiusDefault;
    padding: 8px 12px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    max-width: 320px;

    &:global(.MuiTooltip-tooltipPlacementTop) {
      margin-top: $defaultTopMargin;
    }

    &:global(.MuiTooltip-tooltipPlacementBottom) {
      margin-top: $defaultBottomMargin;
    }

    > span {
      color: $white100Base;

      &::before {
        border: 1px solid $primary120;
      }
    }
  }
}
